import {
  AddLabelsNode,
  ConditionNode,
  ConfirmationNode,
  CustomPromptNode,
  RunMeetingKitNode,
  SaveToSpaceNode,
  ShareNode,
  TemplateNode,
  WorkflowNode,
} from '../models/workflows';
import { Liquid } from 'liquidjs';

export class NodeValidator {
  static validate(node: WorkflowNode): WorkflowNode {
    switch (node.type) {
      case 'SaveToSpace':
        return NodeValidator.validateSaveToSpace(node);
      case 'AddLabels':
        return NodeValidator.validateAddLabels(node);
      case 'Share':
        return NodeValidator.validateShare(node);
      case 'Condition':
        return NodeValidator.validateCondition(node);
      case 'Confirmation':
        return NodeValidator.validateConfirmation(node);
      case 'CustomPrompt':
        return NodeValidator.validateCustomPrompt(node);
      case 'RunMeetingKit':
        return NodeValidator.validateRunMeetingKit(node);
      case 'Template':
        return NodeValidator.validateTemplate(node);

      default:
        return { ...node, data: { ...(node.data ?? {}), isValid: true } };
    }
  }

  private static validateSaveToSpace(node: WorkflowNode): WorkflowNode {
    const data = node.data as SaveToSpaceNode['data'];
    const isValid = Boolean(data.space);
    return { ...node, data: { ...data, isValid } };
  }

  private static validateAddLabels(node: WorkflowNode): WorkflowNode {
    const data = node.data as AddLabelsNode['data'];
    const isValid = Boolean(data.labels);
    return { ...node, data: { ...data, isValid } };
  }

  private static validateShare(node: WorkflowNode): WorkflowNode {
    const data = node.data as ShareNode['data'];
    let isValid = false;
    if (data.slack) {
      isValid =
        Boolean(data.slack?.connectionKey) && isValidLiquid(data.bodyTemplate);
    } else if (data.email) {
      isValid =
        isValidLiquid(data.email?.subject) && isValidLiquid(data.bodyTemplate);
    }
    return { ...node, data: { ...data, isValid } };
  }

  private static validateCondition(node: WorkflowNode): WorkflowNode {
    const data = node.data as ConditionNode['data'];
    return {
      ...node,
      data: { ...data, isValid: isValidLiquid(data.condition) },
    };
  }

  private static validateConfirmation(node: WorkflowNode): WorkflowNode {
    const data = node.data as ConfirmationNode['data'];
    return { ...node, data: { ...data, isValid: isValidLiquid(data.prompt) } };
  }

  private static validateCustomPrompt(node: WorkflowNode): WorkflowNode {
    const data = node.data as CustomPromptNode['data'];
    return { ...node, data: { ...data, isValid: isValidLiquid(data.prompt) } };
  }

  private static validateRunMeetingKit(node: WorkflowNode): WorkflowNode {
    const data = node.data as RunMeetingKitNode['data'];
    const isValid = Boolean(data.meetingKitId && data.meetingKitItemId);
    return { ...node, data: { ...data, isValid } };
  }

  private static validateTemplate(node: WorkflowNode): WorkflowNode {
    const data = node.data as TemplateNode['data'];
    return {
      ...node,
      data: { ...data, isValid: isValidLiquid(data.template) },
    };
  }
}

/**
 * Given a template, try to parse it and see if its good liquid syntax
 */
function isValidLiquid(template: string | undefined): boolean {
  try {
    new Liquid().parse(template ?? '');
    return Boolean(template);
  } catch (e) {
    return false;
  }
}
