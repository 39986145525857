import React from 'react';
import { RawBlock, BlockGroup } from '@tactiq/model';
import { FormattedMessage } from 'react-intl';
import { Highlight } from '../../../common/Highlight';
import { ExtendedTranscriptBlock } from '../../types';
import { Avatar, SpeakerColourProps } from '../../../../../components/Avatar';
import { Tooltip } from '../../../../../components/Tooltip';

/**
 * Speaker name component
 * @param {unknown} param0 params
 * @param {number} param0.refIndex refIndex
 * @param {string} param0.speakerName speakerName
 * @param {string} param0.speakerColor speakerColor
 * @param {string} param0.groupType groupType
 * @param {ExtendedTranscriptBlock['matches']} param0.currentMatches currentMatches
 * @param {ExtendedTranscriptBlock['matches']} param0.speakerMatches speakerMatches
 * @param {boolean} param0.short short
 * @returns {React.FC} component
 */
export const SpeakerName: React.FC<{
  refIndex: number;
  speakerName: string;
  speakerColor: string;
  groupType: BlockGroup<RawBlock>['type'];
  currentMatches: ExtendedTranscriptBlock['matches'];
  speakerMatches: ExtendedTranscriptBlock['matches'];
}> = ({
  refIndex,
  speakerName,
  groupType,
  currentMatches,
  speakerMatches,
  speakerColor,
}) => {
  let speakerComponent = null;

  switch (groupType) {
    case 'screenshot': {
      speakerComponent = (
        <Tooltip
          title={
            <FormattedMessage
              defaultMessage="Screenshot"
              id="1ou3yk"
              description="Transcript block. Screenshot icon tooltip text."
            />
          }
        >
          <span>📷</span>
        </Tooltip>
      );
      break;
    }
    case 'chat': {
      speakerComponent = (
        <Tooltip
          title={
            <FormattedMessage
              defaultMessage="Chat message"
              description="Transcript block. Chat message icon tooltip text."
              id="sMUGVi"
            />
          }
        >
          <div className="flex justify-end md:w-12">
            <span className="">💬</span>
          </div>
        </Tooltip>
      );
      break;
    }
    default: {
      speakerComponent = (
        <div className="flex flex-row items-center gap-x-4 sm:gap-x-6 lg:gap-8">
          <div className="flex min-w-8 select-none flex-col items-end sm:w-12">
            <Avatar
              name={speakerName}
              className="h-6 w-6"
              color={speakerColor as SpeakerColourProps}
            />
          </div>
          <div className="flex-1 font-semibold text-slate-700 text-sm">
            <Highlight
              refIndex={refIndex}
              text={speakerName}
              matchKey="speakerName"
              matches={speakerMatches}
              currentMatches={currentMatches}
            />
          </div>
        </div>
      );
    }
  }

  return <div>{speakerComponent}</div>;
};
