import React, { useEffect, useState } from 'react';
import { createPopup, Popup } from '@typeform/embed';
// This is not being added into the page when it is built to environment or production
// import '@typeform/embed/build/css/popup.css';
// This is not being added into the page when it is built to environment or production
import {
  trackRateUsModalClosed,
  trackRateUsModalViewed,
} from '../../../helpers/analytics';
import { RateUsModalSource } from '../../../redux/modules/global';

export interface RateModalProps {
  source: RateUsModalSource;
  uid: string;
  onClose: () => void;
  onSubmit: () => void;
}

/**
 * Rate us Modal
 * @param {unknown} param0 params
 * @param {false | RateUsModalSource} param0.source source
 * @param {string} param0.uid uid
 * @param {() => void} param0.onClose onClose
 * @param {() => void} param0.onSubmit onSubmit
 * @returns {React.FC} component
 */
export const RateModal: React.FC<RateModalProps> = ({
  source,
  uid,
  onClose,
  onSubmit,
}) => {
  const [popup, setPopup] = useState<Popup | null>(null);

  useEffect(() => {
    setPopup(
      createPopup('SDhqcbiM', {
        container: document.body,
        hidden: {
          uid,
        },
        onClose,
        onSubmit,
      })
    );

    return () => {
      if (popup) {
        trackRateUsModalClosed(source);
        popup.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, uid]);

  useEffect(() => {
    if (popup && source) {
      trackRateUsModalViewed(source);
      popup.open();
    }
  }, [popup, open]);

  return null;
};
