import React, { ReactElement, useState } from 'react';
import { Page } from '../Common/Navigation';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useWorkflowItem, useWorkflowUpdate } from '../../services/Workflow';
import { Workflow } from '../../graphql/operations';
import { Spinner } from '../../components/Spinner';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/buttons';
import WorkflowEditor, { WorkflowPreview } from './WorkflowEditor';
import { Edge, MarkerType, Node, ReactFlowProvider } from '@xyflow/react';
import {
  workflowItem,
  workflowItemActivity,
  workflowItemExecution,
  workflowList,
} from '../../helpers/routes';
import { ArrowLeft, Group, SquareActivity } from 'lucide-react';
import WorkflowActivity from './WorkflowActivity';
import { useWorkflowId, WorkflowProvider } from './WorkflowIdContext';
import { useNodeTypeToName } from './nodes';
import { useCurrentUserId } from '../../services/User';
import { WorkflowActionsButton } from './WorkflowActionsButton';

export default function WorkflowItem(props: { tab: string }): ReactElement {
  const { id } = useParams();
  const intl = useIntl();
  const [savedOnce, setSavedOnce] = useState(false);
  if (!id) throw new Error('WorkflowItem used outside of route');
  const { data, loading, error } = useWorkflowItem({ id });
  if (error) throw error;

  return (
    <Page
      title={intl.formatMessage({ defaultMessage: 'Workflow', id: 'pv1lF7' })}
      grow
      maxWidth="full"
      overflow
    >
      {loading || !data?.workflow ? (
        <Spinner className="m-auto my-16" size="1.5rem" />
      ) : (
        <WorkflowProvider
          value={{
            nodeCounter: data.workflow.nodeCounter,
            workflowId: data.workflow.id,
            savedOnce,
          }}
        >
          <WorkflowForm
            workflow={data.workflow}
            tab={props.tab}
            setSavedOnce={setSavedOnce}
          />
        </WorkflowProvider>
      )}
    </Page>
  );
}

function WorkflowForm(props: {
  workflow: Workflow;
  tab: string;
  setSavedOnce: (value: boolean) => void;
}): ReactElement {
  const { tab, setSavedOnce } = props;
  const { id } = props.workflow;
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const currentUserId = useCurrentUserId();
  const navigate = useNavigate();
  const setClean = () => setHasUnsavedChanges(false);
  const workflowSave = useWorkflowUpdate(setClean);
  const { nodeCounter } = useWorkflowId();
  const [name, setName] = useState(props.workflow.name);
  const nodeTypeToName = useNodeTypeToName();
  const isOwner = props.workflow.createdBy.uid === currentUserId;

  const [nodes, setNodes] = useState<Node[]>(
    props.workflow.definition.nodes.length > 0
      ? props.workflow.definition.nodes.map((node) => ({
          ...node,
          height: Math.max(node.height ?? 0, 56),
        }))
      : [
          {
            id: 'step0',
            type: 'StartNode',
            position: { x: 0, y: 0 },
            data: {
              displayName: nodeTypeToName.StartNode,
            },
          },
        ]
  );
  const [edges, setEdges] = useState<Edge[]>(
    (props.workflow.definition.edges ?? []).map((e) => ({
      ...e,
      type: 'smoothstep',
      markerEnd: { type: MarkerType.Arrow },
    }))
  );

  const input = {
    id: props.workflow.id,
    name,
    nodeCounter,
    definition: {
      nodes: nodes.map((ii: Node) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { recentExecutionData, ...restData } = ii.data;
        return {
          id: ii.id,
          type: ii.type,
          position: { x: ii.position.x, y: ii.position.y },
          ...ii.measured,
          data: restData,
        };
      }),
      edges: edges.map((e) => ({
        id: e.id,
        target: e.target,
        source: e.source,
        sourceHandle: e.sourceHandle ? e.sourceHandle : undefined,
        label: e.label as string,
      })),
    },
  };

  return (
    <div className="flex h-full flex-col">
      <div className="flex justify-between gap-3 p-4">
        <div className="flex flex-grow items-center gap-3">
          <Button
            href={workflowList}
            startIcon={<ArrowLeft size="1rem" />}
            variant="secondaryOutline"
          >
            <FormattedMessage defaultMessage="Exit" id="E2sGaF" />
          </Button>
          {isOwner && tab === 'builder' ? (
            <TextInput
              variant="naked"
              className="max-w-md font-medium text-md"
              value={name}
              onChange={setName}
            />
          ) : (
            <div className="max-w-md truncate font-medium text-md">{name}</div>
          )}
        </div>

        <div className="flex items-center gap-1">
          <Button
            href={workflowItem.replace(':id', id)}
            variant={tab === 'builder' ? 'secondaryOutline' : 'naked'}
            startIcon={<Group size="1rem" />}
          >
            <FormattedMessage defaultMessage="Builder" id="+cRDz7" />
          </Button>
          <Button
            href={workflowItemActivity.replace(':id', id)}
            variant={tab === 'activity' ? 'secondaryOutline' : 'naked'}
            startIcon={<SquareActivity size="1rem" />}
          >
            <FormattedMessage defaultMessage="Activity" id="ZmlNQ3" />
          </Button>
          <div className="ml-5">
            <WorkflowActionsButton
              workflow={props.workflow}
              loading={workflowSave.loading}
              onSave={
                tab === 'builder' && props.workflow.canModify
                  ? async () => {
                      await workflowSave.request({ input }).finally(() => {
                        setSavedOnce(true);
                      });
                    }
                  : undefined
              }
              onSuccess={({ executionId }) => {
                navigate(
                  workflowItemExecution
                    .replace(':id', id)
                    .replace(':executionId', executionId)
                );
              }}
            />
          </div>
        </div>
      </div>

      <ReactFlowProvider>
        <div className="h-full w-full flex-grow overflow-auto border-t">
          {tab === 'builder' ? (
            isOwner ? (
              <WorkflowEditor
                workflowId={id}
                nodeCounter={nodeCounter}
                nodes={nodes}
                setNodes={setNodes}
                edges={edges}
                setEdges={setEdges}
                nodeDragThreshold={5}
                recentExecution={props.workflow.recentExecution}
                hasUnsavedChanges={hasUnsavedChanges}
                setHasUnsavedChanges={setHasUnsavedChanges}
              />
            ) : (
              <WorkflowPreview nodes={nodes} edges={edges} />
            )
          ) : (
            <WorkflowActivity workflowId={id} nodeDragThreshold={5} />
          )}
        </div>
      </ReactFlowProvider>
    </div>
  );
}
