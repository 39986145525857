import React from 'react';
import { UiSchema } from './JsonForm';
import { FormattedMessage } from 'react-intl';

export const JsonFormOverrides: Record<string, UiSchema> = {
  slack: [
    {
      path: 'text',
      label: () => <FormattedMessage defaultMessage="Message" id="T7Ry38" />,
    },
  ],
  linear: [
    {
      path: 'title',
      label: () => <FormattedMessage defaultMessage="Title" id="9a9+ww" />,
    },
    {
      path: 'description',
      label: () => (
        <FormattedMessage defaultMessage="Description" id="Q8Qw5B" />
      ),
    },
  ],
  pipedrive: [
    {
      path: 'participants.person_id',
      label: () => <FormattedMessage defaultMessage="Participants" />,
    },
  ],
};

export const ConfigUiOverrides: Record<string, UiSchema> = {
  slack: [
    {
      path: 'conversationId',
      label: () => <FormattedMessage defaultMessage="Channel" id="KeO51o" />,
    },
  ],
  linear: [
    {
      path: 'team_id',
      label: () => <FormattedMessage defaultMessage="Team" id="wsUmh9" />,
    },
  ],
  notion: [
    {
      path: 'database_id',
      label: () => <FormattedMessage defaultMessage="Database" id="jjTzIr" />,
    },
  ],
};
