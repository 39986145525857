import { useMutation } from '@apollo/client';
import { logger } from '@tactiq/model';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from '../../../components/buttons';
import {
  MeetingAccess,
  MeetingAccessType,
  MeetingPlatform,
  UploadFileDocument,
} from '../../../graphql/operations';
import { AnyMeeting } from '../../../models/meeting';
import {
  trackWebEvent,
  trackNewFileTypeRequested,
  trackLanguageOverrideClicked,
} from '../../../helpers/analytics';
import { cx } from '../../../helpers/utils';
import { selectTeam, selectUid } from '../../../redux/selectors';
import { Alert } from '../../../components/Alert';
import { TextInput, labelBaseClasses } from '../../../components/TextInput';
import { ModalDialog } from '../../../components/modals';
import { FileAudio, FileText, FileVideo2, FileUp } from 'lucide-react';
import { setTranscriptUploadProgress } from '../../../redux/modules/global';
import { v4 as uuid } from 'uuid';
import { Combobox } from '../../../components/Combobox';

const textFileTypes = new Set([
  'text/vtt',
  'vtt',
  'txt',
  'text/plain',
  'application/pdf',
  'pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'docx',
  'application/vnd.oasis.opendocument.text',
  'odt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'pptx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'xslx',
  'application/vnd.oasis.opendocument.spreadsheet',
  'ods',
  'application/vnd.oasis.opendocument.presentation',
  'odp',
  'application/rtf',
  'application/x-rtf',
  'text/rtf',
  'text/richtext',
  'rtf',
]);

const audioFileTypes = new Set([
  'mp4',
  'video/mp4',
  'm4a',
  'audio/x-m4a',
  'mp3',
  'audio/mpeg',
]);

const videoFileTypes = new Set(['mp4', 'video/mp4', 'mov', 'video/quicktime']);

const allFileTypes = new Set([
  ...textFileTypes,
  ...audioFileTypes,
  ...videoFileTypes,
]);

// collection of unused parameters we can spread to uploading meeting so that we don't have to set them
const defaultMeeting: AnyMeeting = {
  id: '',
  title: '',
  tags: [],
  labels: [],
  duration: 0,
  access: MeetingAccess.READ,
  accessType: MeetingAccessType.OWNER,
  speechDuration: 0,
  modified: 0,
  isDraft: false,
  isUploading: false,
  hadProcessingError: false,
  isPreview: false,
  hasEnded: true,
  screenshotsCount: 0,
  languageCode: '',
  userId: '',
  commentsCount: 0,
  views: [],
  changes: [],
  participants: [],
  shares: [],
  transcripts: [],
  // Fields below are set by "set progress action"
  uploadProgress: 0,
  platform: MeetingPlatform.UNKNOWN,
  created: 0,
  hadAiUsage: false,
};

const whichMedia = (mediaType: string): MeetingPlatform => {
  if (textFileTypes.has(mediaType)) {
    return MeetingPlatform.UPLOADED_TRANSCRIPT;
  } else if (audioFileTypes.has(mediaType)) {
    return MeetingPlatform.UPLOADED_AUDIO;
  } else if (videoFileTypes.has(mediaType)) {
    return MeetingPlatform.UPLOADED_VIDEO;
  } else {
    return MeetingPlatform.UNKNOWN;
  }
};

// padded zero
const pz = (n: number, length = 2) => {
  const str = n.toString();
  return str.length >= length ? str : '0'.repeat(length - str.length) + str;
};

// format date (yyyy-MM-ddThh:mm)
const fmtDate = (d: Date) =>
  `${pz(d.getFullYear(), 4)}-${pz(d.getMonth() + 1)}-${pz(d.getDate())}T${pz(
    d.getHours()
  )}:${pz(d.getMinutes())}`;

/**
 * Import Transcript Dialog
 * @param {unknown} param0 params
 * @param {boolean} param0.isOpen is open
 * @param {() => void} param0.setClose close function
 * @returns {React.FC} a react component
 */
export const ImportTranscriptDialog: React.FC<{
  isOpen: boolean;
  setClose: () => void;
  isOnboarding?: boolean;
}> = ({ isOpen, setClose, isOnboarding }) => {
  const dispatch = useDispatch();
  const [importedFile, setImportedFile] = React.useState<File>();
  const [formData, setFormData] = React.useState<{
    title: string;
    createdAt: Date;
  }>({
    title: '',
    createdAt: new Date(),
  });
  const [fileName, setFileName] = React.useState<string>();
  const [platform, setPlatform] = React.useState<MeetingPlatform>(
    MeetingPlatform.UNKNOWN
  );
  const [message, setMessage] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [hasNoFileError, setHasNoFileError] = React.useState<boolean>(false);

  const dropzoneClasses =
    'flex justify-center items-center h-[200px] w-full cursor-pointer rounded-lg border-dashed border my-6';

  useEffect(() => {
    if (importedFile) {
      setHasNoFileError(false);
    }
  }, [importedFile]);

  const [unsupportedFileType, setUnsupportedFileType] =
    React.useState<string>();
  const intl = useIntl();
  const userId = useSelector(selectUid);
  const team = useSelector(selectTeam);

  // list of languages supported by whisper
  const transcribeLanguages: { id: string; name: string }[] = [
    {
      id: 'auto',
      name: intl.formatMessage({ defaultMessage: 'auto-detect', id: 'GPhtli' }),
    },
    { id: 'en', name: 'English' },
    { id: 'es', name: 'Español' },
    { id: 'fr', name: 'Français' },
    { id: 'de', name: 'Deutsch' },
    { id: 'it', name: 'Italiano' },
    { id: 'pt', name: 'Português' },
    { id: 'nl', name: 'Nederlands' },
    { id: 'ru', name: 'Русский' },
    { id: 'zh', name: '中文' },
    { id: 'ja', name: '日本語' },
    { id: 'ko', name: '한국어' },
    { id: 'ar', name: 'اَلْعَرَبِيَّةُ' },
  ];

  const [transcriptLanguage, setTranscriptLanguage] = React.useState(
    transcribeLanguages[0].id
  );

  const logFileTypeInterest = () => {
    trackNewFileTypeRequested({
      userId,
      fileType: unsupportedFileType,
    });
    enqueueSnackbar(
      intl.formatMessage({
        defaultMessage:
          'Thanks for the request! We we will look into adding this file type to our supported list.',
        id: '62OFVv',
      }),
      { variant: 'INFO' }
    );
  };

  const [uploadFile, uploadFileMutation] = useMutation(UploadFileDocument);

  const uploadTranscript = React.useCallback(async () => {
    const MAX_UPLOAD_RETRY_TIME_MILLISECONDS = 600000;
    if (!importedFile) return;
    if (loading) return;

    setLoading(true);

    const storage = getStorage();
    storage.maxUploadRetryTime = MAX_UPLOAD_RETRY_TIME_MILLISECONDS;
    const storageRef = ref(
      storage,
      `uploads/${userId}/${Date.now()}.${importedFile.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, importedFile);
    const inProgressMeetingId = `upload-${uuid()}`;

    setLoading(false);
    setClose();
    setFileName('');
    setImportedFile(undefined);

    const title = formData.title || 'Imported transcript';
    dispatch(
      setTranscriptUploadProgress({
        ...defaultMeeting,
        id: inProgressMeetingId,
        uploadProgress: 0,
        platform,
        title,
        created: formData.createdAt.getTime(),
      })
    );

    uploadTask.on(
      'state_changed',
      (file) => {
        // Locked down to 80% because the actual upload mutation
        // can take some time, and the ui state changes at 100%
        const progress = Math.min(
          80,
          Math.round((file.bytesTransferred / file.totalBytes) * 100)
        );
        dispatch(
          setTranscriptUploadProgress({
            ...defaultMeeting,
            id: inProgressMeetingId,
            uploadProgress: progress,
            platform,
            title,
            created: formData.createdAt.getTime(),
          })
        );
      },
      (error: Error) => {
        alert(error.message);
      },
      async () => {
        await uploadFile({
          variables: {
            input: {
              fileName: uploadTask.snapshot.ref.name,
              title: formData.title || 'Imported transcript',
              platform,
              createdAt: formData.createdAt.getTime(),
              ...(transcriptLanguage === 'auto'
                ? {}
                : { languageCode: transcriptLanguage }),
            },
          },
        });

        dispatch(
          setTranscriptUploadProgress({
            ...defaultMeeting,
            id: inProgressMeetingId,
            uploadProgress: 100,
            platform,
            title,
            created: formData.createdAt.getTime(),
          })
        );

        trackWebEvent('User uploaded a file', {
          fileType:
            importedFile.type !== ''
              ? importedFile.type
              : importedFile.name.split('.').pop(),
          fileSizeMb: Math.floor(importedFile.size / 1048576),
          platform,
          team_id: team?.id,
          isOnboarding,
        });

        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage: 'File uploaded successfully',
            id: 'h8TY++',
          }),
          { variant: 'SUCCESS' }
        );
      }
    );
  }, [
    dispatch,
    importedFile,
    loading,
    userId,
    uploadFile,
    platform,
    formData.title,
    formData.createdAt,
    intl,
    setClose,
    team,
    transcriptLanguage,
  ]);

  const onDrop = React.useCallback(
    async (files: File[]) => {
      if (files.length > 1) {
        const fileTypes = new Set(
          files.map((uploadedFile) =>
            uploadedFile.type === ''
              ? uploadedFile.name.split('.').pop()
              : uploadedFile.type
          )
        );
        trackWebEvent('User tried to upload multiple files at once', {
          fileCount: files.length,
          fileTypes: Array.from(fileTypes).join(', '),
        });
        return setMessage(
          intl.formatMessage({
            defaultMessage:
              'We can only process one file at a time... For now...',
            id: 'uXmkv7',
          })
        );
      }

      const file = files[0];
      if (file.size > 2048 * 1048576) {
        trackWebEvent('Upload file attempt failed due to size limit', {
          fileSizeMb: Math.floor(file.size / 1048576),
          fileType: file.type,
        });
        return setMessage(
          intl.formatMessage({
            defaultMessage: 'File too large. Maximum file size is 2GB.',
            id: 'jkfxUu',
          })
        );
      }

      const fileParts = file.name.split('.');
      const fileType = (file.type || fileParts[fileParts.length - 1]) ?? '';
      const filePlatform = whichMedia(fileType);
      if (filePlatform === MeetingPlatform.UNKNOWN) {
        trackWebEvent('User tried to upload an unsupported file type', {
          fileType,
        });
        setUnsupportedFileType(fileType);
        return setMessage(
          intl.formatMessage({
            defaultMessage: "We don't currently support that file type.",
            id: 'bFobP6',
          })
        );
      }
      setMessage(null);
      setUnsupportedFileType(undefined);
      setFileName(file.name);
      setPlatform(filePlatform);
      setFormData({ ...formData, title: fileParts[0] });
      setImportedFile(file);
    },
    [formData, intl]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
  });

  if (!isOpen) return null;
  /**
   *
   */
  function handleClose() {
    setImportedFile(undefined);
    setFileName('');
    setClose();
  }

  const handleLanguageOverrideClick = (
    language: {
      id: string;
      name: string;
    } | null
  ) => {
    const newLanguage = language ? language.id : 'auto';
    trackLanguageOverrideClicked({
      userId,
      language: newLanguage,
    });
    setTranscriptLanguage(newLanguage);
  };

  const iconClasses = 'h-8 w-8';
  const animatedIconClasses = `${iconClasses} animate-bounce text-indigo-600`;
  let fileIcon = <FileUp className={iconClasses} />;
  switch (platform) {
    case MeetingPlatform.UNKNOWN:
    case MeetingPlatform.GOOGLE_MEET:
    case MeetingPlatform.ZOOM:
    case MeetingPlatform.ZOOM_API:
    case MeetingPlatform.WEBEX_API:
    case MeetingPlatform.MS_TEAMS:
      break;
    case MeetingPlatform.UPLOADED_TRANSCRIPT:
      fileIcon = <FileText className={animatedIconClasses} />;
      break;
    case MeetingPlatform.UPLOADED_AUDIO:
      fileIcon = <FileAudio className={animatedIconClasses} />;
      break;
    case MeetingPlatform.UPLOADED_VIDEO:
      fileIcon = <FileVideo2 className={animatedIconClasses} />;
      break;
  }

  return (
    <ModalDialog
      open={isOpen}
      onClose={() => !loading && handleClose()}
      title={
        <div className="flex flex-col gap-1">
          <p className="font-bold text-2xl">
            {loading ? (
              <FormattedMessage
                defaultMessage="Uploading, please do not close this page..."
                id="Iffja4"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Upload a transcript or a recording"
                id="EQ+8DW"
              />
            )}
          </p>
        </div>
      }
      actions={
        <>
          {unsupportedFileType ? (
            <Button onClick={logFileTypeInterest}>
              <FormattedMessage
                defaultMessage="Request {fileType} support"
                id="GqvJPv"
                values={{ fileType: unsupportedFileType }}
              />
            </Button>
          ) : (
            <Button
              loading={loading || uploadFileMutation.loading}
              onClick={async () => {
                if (!importedFile) {
                  enqueueSnackbar(
                    intl.formatMessage({
                      defaultMessage: 'Please select the file first',
                      id: '2TfF77',
                    }),
                    { variant: 'WARNING' }
                  );
                  setHasNoFileError(true);
                  return;
                }
                try {
                  await uploadTranscript();
                } catch (error) {
                  logger.error(error);
                  enqueueSnackbar(
                    intl.formatMessage({
                      defaultMessage:
                        'Something went wrong during file upload.',
                      id: '2FilcO',
                    }),
                    { variant: 'ERROR' }
                  );
                }
              }}
            >
              <FormattedMessage defaultMessage="Upload" id="p4N05H" />
            </Button>
          )}

          <Button onClick={setClose} variant="outlined">
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
        </>
      }
    >
      <div
        role="button"
        onClick={open}
        {...getRootProps({ className: 'dropzone' })}
        className={cx(
          dropzoneClasses,
          hasNoFileError ? 'border-red-500 bg-red-100' : 'border-slate-300',
          isDragActive
            ? 'border-indigo-600 bg-indigo-50 text-indigo-600'
            : 'text-slate-400'
        )}
      >
        <input {...getInputProps()} />
        {!fileName && !hasNoFileError && (
          <div className="flex w-full flex-col items-center justify-center gap-4 p-4">
            {fileIcon}
            <p
              className={cx(
                'text-sm',
                isDragActive ? 'text-indigo-600' : 'text-slate-700'
              )}
            >
              <FormattedMessage
                defaultMessage="Drag and drop file here or {chooseFile}"
                id="xMURWf"
                values={{
                  chooseFile: (
                    <span className="underline">
                      <FormattedMessage
                        defaultMessage="choose file"
                        id="QbBJGp"
                      />
                    </span>
                  ),
                }}
              />
            </p>
          </div>
        )}
        {!fileName && hasNoFileError && (
          <div className="text-md text-slate-700">
            <FormattedMessage
              defaultMessage="Please upload a file"
              id="L96c79"
            />
          </div>
        )}
        {!isDragActive && fileName && (
          <div className="flex w-full flex-col items-center justify-center gap-4 p-4">
            {fileIcon}
            <div className="text-md text-slate-700">{fileName}</div>
          </div>
        )}
      </div>
      <div className="mb-6 flex flex-col gap-1 text-slate-500 text-sm">
        <p className="font-medium text-xs">
          <FormattedMessage
            defaultMessage="Supported formats: {supported}"
            id="zppxmw"
            values={{
              supported: (
                <span className="italic">
                  {[...allFileTypes]
                    .filter((type) => type.length <= 4)
                    .join(', ')}
                </span>
              ),
            }}
          />
        </p>
        <p className="font-medium">
          <FormattedMessage
            defaultMessage="Maximum file size: {size}"
            id="oRH5qL"
            values={{
              size: (
                <span className="font-normal">
                  <FormattedMessage defaultMessage="2GB" id="/5QNHU" />
                </span>
              ),
            }}
          />
        </p>
      </div>

      <div className="flex flex-col gap-6">
        <form
          className="flex flex-col gap-4"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="flex flex-col gap-4 md:flex-row">
            <TextInput
              id="title"
              label="Meeting title"
              placeholder="Imported meeting"
              autoFocus
              value={formData.title}
              onChange={(value) => setFormData({ ...formData, title: value })}
            />
            <div className="flex flex-col items-baseline gap-1">
              <label
                htmlFor="createdAt"
                className={cx(labelBaseClasses, 'text-slate-600')}
              >
                <FormattedMessage
                  defaultMessage="Meeting start time"
                  id="6HivPf"
                />
              </label>
              <div className="form-input mx-[2px] my-[1px] rounded-md border-0 p-0 text-slate-900 text-sm leading-6 tracking-normal ring-1 ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset md:text-base">
                <input
                  className="rounded-md border-0 px-3 py-1"
                  id="createdAt"
                  type="datetime-local"
                  min={'2000-00-00T00:00'}
                  max={fmtDate(new Date())}
                  value={fmtDate(formData.createdAt)}
                  onChange={(e) => {
                    // ensure valid date can be constructed
                    if (!isNaN(Date.parse(e.currentTarget.value))) {
                      const date = new Date(e.currentTarget.value);
                      setFormData({ ...formData, createdAt: date });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-1 align-center text-slate-600 text-xs">
            <FormattedMessage
              defaultMessage="Transcription language:"
              id="bTUuT9"
            >
              {(msg) => <span className="self-center">{msg}</span>}
            </FormattedMessage>
            <Combobox
              id={(x) => x.id}
              name={(x) => x.name}
              options={transcribeLanguages}
              value={transcribeLanguages.find(
                (x) => x.id === transcriptLanguage
              )}
              onChange={handleLanguageOverrideClick}
              multiple={false}
              hideSearch
              keepSelected
              highlightWhenSelected={false}
              variant="naked"
              size="xs"
            />
          </div>
        </form>
        {message && <Alert severity="warning" description={message} />}
      </div>
    </ModalDialog>
  );
};
